import { useState, useContext, useEffect } from "react"
import cc from "classcat"
import { LocaleContext } from "contexts"
import useTranslations from "hooks/useTranslations"
import useShowLocaleSwitch from "hooks/useShowLocaleSwitch"
import Cookies from "js-cookie"
import { useLocation } from "@reach/router"
import Modal from "components/molecules/Modal"
import LanguageSelector from "components/molecules/LanguageSelector"
import {
  COOKIE_NOTICE_ACCEPTED_NAME,
  COOKIE_ANALYTICS_ENABLED_NAME,
  COOKIE_FUNCTIONAL_ENABLED_NAME,
} from "utils/constants"
import { maybeLoadCrispChat, maybeLoadPlausible } from "utils/browser"

const Button = ({ children, className, onClick, disabled, selected }) => {
  return (
    <button
      onClick={!disabled && onClick}
      className={cc([
        " whitespace-pre border border-teal-400 px-4 py-3 font-display text-sm leading-6 text-zinc-800 hover:bg-teal-500 focus:outline-none active:bg-teal-400 disabled:cursor-not-allowed disabled:bg-zinc-200 disabled:opacity-50 sm:py-2",
        className,
        {
          "rounded-full": !className || !className.includes("rounded"),
          "bg-teal-400": selected,
        },
      ])}
      type="button"
      disabled={disabled}
    >
      {children}
    </button>
  )
}

const SmallP = ({ children }) => {
  return (
    <p className="mb-0 inline-block flex-1 text-sm leading-snug text-zinc-900">
      {children}
    </p>
  )
}

const Title = ({ children }) => {
  return (
    <p className="mb-0 inline-block flex-1 text-base font-bold leading-snug text-zinc-900">
      {children}
    </p>
  )
}

const CookieGroup = ({ title, description, children }) => {
  return (
    <div className="border-top items-center py-4 md:flex md:space-x-4">
      <div className="mb-1 mt-1 flex-1 text-sm md:mb-0">
        <h5 className="mb-0 text-base">{title}</h5>
        <SmallP>{description}</SmallP>
      </div>
      <div className="flex items-center justify-end text-right">{children}</div>
    </div>
  )
}

const CookieBanner = ({ dispatchCookieUpdate }) => {
  const { locale } = useContext(LocaleContext)
  const { showLocalesSwitch, locales } = useShowLocaleSwitch()
  const [currentLocale, setCurrentLocale] = useState(locale)
  const cookiePolicyAccepted = Cookies.get(COOKIE_NOTICE_ACCEPTED_NAME)
  const [show, setShow] = useState(false)
  useEffect(() => {
    if (cookiePolicyAccepted !== "true") {
      setShow(true)
    }
  }, [cookiePolicyAccepted])
  const [showMore, setShowMore] = useState(false)
  const analyticsEnabled = Cookies.get(COOKIE_ANALYTICS_ENABLED_NAME)
  const functionalEnabled = Cookies.get(COOKIE_FUNCTIONAL_ENABLED_NAME)
  const [acceptAnalytics, setAcceptAnalytics] = useState(
    analyticsEnabled === "true",
  )
  const [rejectAnalytics, setRejectAnalytics] = useState(
    !(analyticsEnabled === "true"),
  )
  const [acceptFunctional, setAcceptFunctional] = useState(
    functionalEnabled === "true",
  )
  const [rejectFunctional, setRejectFunctional] = useState(
    !(functionalEnabled === "true"),
  )
  const {
    cookieBannerTitle,
    cookieBannerText1,
    cookieBannerText2,
    cookieBannerButtonMore,
    cookieBannerButtonAcceptAll,
    cookieBannerButtonRejectAll,
    cookieBannerEssentialTitle,
    cookieBannerEssentialDescription,
    cookieBannerEssentialAlwaysActive,
    cookieBannerFunctionalTitle,
    cookieBannerFunctionalDescription,
    cookieBannerAnalyticsTitle,
    cookieBannerAnalyticsDescription,
    cookieBannerButtonLess,
    cookieBannerButtonAccept,
    cookieBannerButtonReject,
    cookieBannerButtonSave,
    cookieBannerLinkText,
    cookiePolicyLink,
  } = useTranslations(currentLocale)

  const acceptCookieNotice = () => {
    Cookies.set(COOKIE_NOTICE_ACCEPTED_NAME, true, { expires: 365 })
    setShow(false)
  }

  const enableAnalytics = () => {
    Cookies.set(COOKIE_ANALYTICS_ENABLED_NAME, true, { expires: 365 })
    maybeLoadPlausible()
    dispatchCookieUpdate({ analytics: "enabled" })
  }
  const disableAnalytics = () => {
    Cookies.set(COOKIE_ANALYTICS_ENABLED_NAME, false, { expires: 365 })
    dispatchCookieUpdate({ analytics: "disabled" })
  }

  const enableFunctional = () => {
    Cookies.set(COOKIE_FUNCTIONAL_ENABLED_NAME, true, { expires: 365 })
    maybeLoadCrispChat()
    dispatchCookieUpdate({ functional: "enabled" })
  }

  const disableFunctional = () => {
    Cookies.set(COOKIE_FUNCTIONAL_ENABLED_NAME, false, { expires: 365 })
    dispatchCookieUpdate({ functional: "disabled" })
  }

  const onAcceptAll = () => {
    enableAnalytics()
    enableFunctional()
    acceptCookieNotice()
  }

  const onRejectAll = () => {
    disableAnalytics()
    disableFunctional()
    acceptCookieNotice()
  }

  const onRejectAnalytics = () => {
    setRejectAnalytics(true)
    setAcceptAnalytics(false)
  }

  const onAcceptAnalytics = () => {
    setAcceptAnalytics(true)
    setRejectAnalytics(false)
  }

  const onRejectFunctional = () => {
    setRejectFunctional(true)
    setAcceptFunctional(false)
  }

  const onAcceptFunctional = () => {
    setAcceptFunctional(true)
    setRejectFunctional(false)
  }

  const onSavePreferences = () => {
    if (acceptAnalytics) {
      enableAnalytics()
    }
    if (!acceptAnalytics) {
      disableAnalytics()
    }
    if (acceptFunctional) {
      enableFunctional()
    }
    if (!acceptFunctional) {
      disableFunctional()
    }
    acceptCookieNotice()
  }

  const closeModal = () => {
    setShow(false)
  }

  const handleLocaleChange = (value) => {
    setCurrentLocale(value)
  }

  return (
    <Modal
      isOpen={show}
      onRequestClose={closeModal}
      showCloseButton={false}
      closeOnClickAway={false}
    >
      <div className="-mb-px border-0 ">
        <div className="" hidden={showMore}>
          <div className="flex flex-col items-center space-y-4 text-center align-middle">
            <Title>{cookieBannerTitle}</Title>
            <SmallP>
              {cookieBannerText1}
              <br />
              <a
                href={cookiePolicyLink}
                className="ml-1 underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                {cookieBannerLinkText}
              </a>
              .
              <br />
              {cookieBannerText2}
            </SmallP>
            <div className="flex flex-col space-y-3">
              <div className="flex flex-col space-y-3 xs:flex-row xs:space-x-2 xs:space-y-0 sm:flex-nowrap md:mt-0 md:block lg:flex lg:flex-1 lg:items-center">
                <Button
                  onClick={onAcceptAll}
                  className="mr-1 flex-1 bg-teal-400 sm:mx-1 md:m-0 lg:block"
                >
                  {cookieBannerButtonAcceptAll}
                </Button>
                <Button
                  onClick={onRejectAll}
                  className="mr-1 flex-1 bg-teal-400 sm:mx-1 md:m-0 lg:block"
                >
                  {cookieBannerButtonRejectAll}
                </Button>
              </div>
              <Button
                onClick={() => setShowMore(true)}
                className="w-full sm:mr-1 sm:w-auto sm:flex-1 md:mr-0 lg:block"
              >
                {cookieBannerButtonMore}
              </Button>
            </div>
            {showLocalesSwitch ? (
              <div className="mx-auto w-36">
                <LanguageSelector
                  locales={locales}
                  handleOnChange={handleLocaleChange}
                />
              </div>
            ) : null}
          </div>
        </div>

        <div className="divide-y divide-zinc-400" hidden={!showMore}>
          <div className="flex items-center justify-between space-x-4 py-2">
            <div className="flex flex-col">
              <Title>{cookieBannerTitle}</Title>
              <SmallP>
                {cookieBannerText1}
                <a
                  href={cookiePolicyLink}
                  className="ml-1 underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {cookieBannerLinkText}
                </a>
                .
              </SmallP>
            </div>
            <div className="flex">
              <Button
                className="mr-1 flex-1 sm:mx-1 md:m-0 lg:block"
                onClick={() => setShowMore(false)}
              >
                {cookieBannerButtonLess}
              </Button>
            </div>
          </div>

          <CookieGroup
            title={cookieBannerEssentialTitle}
            description={cookieBannerEssentialDescription}
          >
            <p className="m-0 justify-end font-display text-sm leading-normal text-zinc-800">
              {cookieBannerEssentialAlwaysActive}
            </p>
          </CookieGroup>

          <CookieGroup
            title={cookieBannerFunctionalTitle}
            description={cookieBannerFunctionalDescription}
          >
            <div className="mt-md-0 ml-2 mt-1 inline-block align-middle">
              <Button
                className="rounded-l-full border-r"
                selected={acceptFunctional}
                onClick={onAcceptFunctional}
              >
                {cookieBannerButtonAccept}
              </Button>
              <Button
                className="rounded-r-full border-l"
                selected={rejectFunctional}
                onClick={onRejectFunctional}
              >
                {cookieBannerButtonReject}
              </Button>
            </div>
          </CookieGroup>

          <CookieGroup
            title={cookieBannerAnalyticsTitle}
            description={cookieBannerAnalyticsDescription}
          >
            <div className="mt-md-0 ml-2 mt-1 inline-block align-middle">
              <Button
                className="rounded-l-full border-r"
                selected={acceptAnalytics}
                onClick={onAcceptAnalytics}
              >
                {cookieBannerButtonAccept}
              </Button>
              <Button
                className="rounded-r-full border-l"
                selected={rejectAnalytics}
                onClick={onRejectAnalytics}
              >
                {cookieBannerButtonReject}
              </Button>
            </div>
          </CookieGroup>

          <div className="border-top justify-between space-y-3 pt-3 sm:flex sm:flex-row-reverse sm:space-y-0">
            <div className=" text-right">
              <Button
                className="border-none bg-zinc-400"
                type="button"
                disabled={
                  (!acceptAnalytics && !rejectAnalytics) ||
                  (!acceptFunctional && !rejectFunctional)
                }
                onClick={onSavePreferences}
              >
                {cookieBannerButtonSave}
              </Button>
            </div>
            <div className="flex justify-between space-x-3">
              <Button
                className="flex-1 bg-teal-400 sm:mx-1 sm:mr-1 md:m-0 lg:block"
                onClick={onAcceptAll}
              >
                {cookieBannerButtonAcceptAll}
              </Button>
              <Button
                className="flex-1 bg-teal-400 sm:mx-1 sm:mr-1 md:m-0 lg:block"
                onClick={onRejectAll}
              >
                {cookieBannerButtonRejectAll}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CookieBanner
