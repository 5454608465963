import "@stripe/stripe-js"
import Cookies from "js-cookie"
import pick from "lodash.pick"
import get from "lodash.get"
import {
  handleScrollToAnchor,
  maybeLoadCrispChat,
  maybeLoadPlausible,
} from "utils/browser"
import { COOKIE_NOTICE_ACCEPTED_NAME } from "utils/constants"
import CustomLayout from "./wrapPageElement"
import countries from "./config/i18n"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./src/styles/tailwind.css"
import "./src/components/layout.css"

const siteCountry = process.env.GATSBY_SITE_COUNTRY || "fi"
const locales = countries[siteCountry] || countries.fi

export const wrapPageElement = CustomLayout

const handleUrlQueries = () => {
  const params = new URLSearchParams(window.location.search)
  if (params.has("partner")) {
    Cookies.set("partner", params.get("partner"))
  }
}

const getUrlVars = () => {
  const vars = {}
  if (window) {
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
      vars[key] = value
    })
  }
  return vars
}

const utmCodes = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_content",
  "utm_term",
]

const parseAndStoreUtmCodes = () => {
  const urlVars = getUrlVars()
  const objectToStore = pick(urlVars, utmCodes)

  if (Object.keys(objectToStore).length > 0) {
    sessionStorage.setItem("utmCodes", JSON.stringify(objectToStore))
  }
}

export const onClientEntry = () => {
  maybeLoadCrispChat()
  maybeLoadPlausible()
}

/* eslint-disable no-restricted-globals */
export const onInitialClientRender = () => {
  parseAndStoreUtmCodes()
  handleUrlQueries()
  handleScrollToAnchor()
}

const browserRedirects = [
  ["/onboarding/", "/"],
  ["/blog/tags/", "/blog"],
  ["/some/", "/"],
  ["/testimonials/", "/"],
  ["/lessons/", "/"],
]

export const onPreRouteUpdate = ({ location }) => {
  const params = new URLSearchParams(location.search)
  if (params.get("showCookieBanner") === "true") {
    Cookies.set(COOKIE_NOTICE_ACCEPTED_NAME, "false", {
      expires: 365,
    })
    params.set("showCookieBanner", "false")
    window.history.replaceState({}, "", `${location.pathname}`)
  }
}

export const onRouteUpdate = ({ location }) => {
  Object.keys(locales).map((localeConfig) => {
    const { locale } = locales[localeConfig]
    const isDefault = !!get(locales[localeConfig], "default")

    browserRedirects.forEach(([from, to]) => {
      const fromPath = isDefault ? from : `/${locale}${from}`
      const toPath = isDefault ? to : `/${locale}${to}`
      if (location.pathname === fromPath) {
        window.location.href = toPath
      }
    })
    return true
  })
}
