import PropTypes from "prop-types"
import { handleScrollToAnchor } from "utils/browser"
import { OutboundLink } from "components/atoms/OutboundLink"
import { fillWithUtm } from "utils/url"
import LocalizedLink from "../LocalizedLink"
import CoreButton from "./CoreButton"

const wrappedOnClick = (onClick, event) => {
  if (onClick) {
    event.preventDefault()
    onClick(event)
  }
}

const handleOnClick = (onClick, link, label) => (event) => {
  wrappedOnClick(onClick, event)
  if (link && link.includes("#")) handleScrollToAnchor(link)
}

const Button = ({ link, onClick = null, outbound = false, ...props }) => {
  const { label } = props
  if (outbound) {
    const filledLink = fillWithUtm(link)

    return (
      <OutboundLink
        className="flex justify-center no-underline"
        href={filledLink}
        tabIndex={-1}
      >
        <CoreButton {...props} />
      </OutboundLink>
    )
  }
  return (
    <LocalizedLink
      to={link}
      className="flex justify-center no-underline"
      onClick={handleOnClick(onClick, link, label)}
      tabIndex={-1}
    >
      <CoreButton {...props} />
    </LocalizedLink>
  )
}

Button.defaultProps = {
  outbound: false,
}

Button.propTypes = {
  link: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  outbound: PropTypes.bool,
}

export default Button
