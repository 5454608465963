import { Fragment, useState, useContext } from "react"
import { LocaleContext } from "contexts"
import { Listbox, Transition } from "@headlessui/react"
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid"
import { GlobeAltIcon } from "@heroicons/react/24/outline"
import cc from "classcat"

export default function LanguageSelector({ locales, handleOnChange }) {
  const { locale } = useContext(LocaleContext)

  const [selectedLang, setSelectedLang] = useState(
    locales.find((l) => l.locale === locale),
  )

  const onChange = (value) => {
    setSelectedLang(locales.find((l) => l.locale === value))
    handleOnChange(value)
  }

  return (
    <Listbox value={selectedLang.locale} onChange={onChange}>
      {({ open }) => (
        <div className="relative">
          <Listbox.Button className="elative flex w-full cursor-default rounded-full bg-white py-1 pl-3 pr-8 text-left text-sm focus:outline-none focus-visible:border-teal-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300">
            <span className="inline-flex truncate ">
              <GlobeAltIcon className="mr-2 inline h-5 w-5" />
              {selectedLang.lang}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
              <ChevronUpDownIcon
                className="h-5 w-5 text-zinc-500"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              static
              className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              {locales.map((lang) => (
                <Listbox.Option
                  key={lang.locale}
                  className={({ active }) =>
                    cc([
                      active ? "bg-teal-100 text-teal-900" : "text-zinc-900",
                      "relative cursor-default select-none py-2 pl-10 pr-4 text-sm",
                    ])
                  }
                  value={lang.locale}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={cc([
                          selected ? "font-medium" : "font-normal",
                          "block truncate text-left",
                        ])}
                      >
                        {lang.lang}
                      </span>
                      {selected ? (
                        <span
                          className={cc([
                            active ? "text-teal-600" : "text-teal-600",
                            "absolute inset-y-0 left-0 flex items-center pl-3",
                          ])}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  )
}
