const siteCountry = process.env.GATSBY_SITE_COUNTRY
// Only one locale per country MUST have the "default: true" key
module.exports = {
  fi: {
    "fi-fi": {
      default: siteCountry === "fi",
      path: `fi-fi`,
      locale: `fi-fi`,
      siteLanguage: `fi-fi`,
      ogLanguage: `fi-fi`,
      dateFormat: `DD/MM/YYYY`,
      defaultTitle: `Someturva`,
      defaultDescription: `Turvaa itsesi tai läheisesi sosiaalisessa mediassa.`,
      langName: "Suomeksi",
      url: `https://someturva.fi`,
      country: `Suomi`,
      social: {
        twitter: "https://twitter.com/someturva",
        instagram: "https://www.instagram.com/someturva/",
        facebook: "https://www.facebook.com/Someturva/",
        linkedin: "https://www.linkedin.com/company/someturva",
      },
      showBanner: false,
      showNewsletterSubscription: true,
      contactEmail: "info@someturva.fi",
      katiEmail: "kati.nevatalo@someturva.fi",
      contactPhone: "+358505053134",
      appLink: "https://palvelu.someturva.fi",
    },
    "sv-fi": {
      default: false,
      path: `sv-fi`,
      locale: `sv-fi`,
      siteLanguage: `sv-fi`,
      ogLanguage: `sv-fi`,
      dateFormat: `DD.MM.YYYY`,
      defaultTitle: `Someturva`,
      defaultDescription: `En onlinetjänst som erbjuder hjälp för dig som upplevt problem eller blivit utsatt på sociala medier`,
      langName: "På Svenska",
      url: `https://someturva.fi`,
      country: `Suomi`,
      social: {
        twitter: "https://twitter.com/someturva",
        instagram: "https://www.instagram.com/someturva/",
        facebook: "https://www.facebook.com/Someturva/",
        linkedin: "https://www.linkedin.com/company/someturva",
      },
      showBanner: false,
      showNewsletterSubscription: true,
      contactEmail: "info@someturva.fi",
      katiEmail: "kati.nevatalo@someturva.fi",
      contactPhone: "+358505053134",
      appLink: "https://login.somebuddy.com/?locale=sv-FI",
    },
    "en-fi": {
      default: false,
      path: `en-fi`,
      locale: `en-fi`,
      siteLanguage: `en-fi`,
      ogLanguage: `en-fi`,
      dateFormat: `DD.MM.YYYY`,
      defaultTitle: `Someturva`,
      defaultDescription: ``,
      langName: `In English`,
      url: `https://someturva.fi`,
      country: `Finland`,
      social: {
        twitter: "https://twitter.com/someturva",
        instagram: "https://www.instagram.com/someturva/",
        facebook: "https://www.facebook.com/Someturva/",
        linkedin: "https://www.linkedin.com/company/someturva",
      },
      showBanner: false,
      showNewsletterSubscription: true,
      contactEmail: "info@someturva.fi",
      katiEmail: "kati.nevatalo@someturva.fi",
      contactPhone: "+358505053134",
      appLink: "https://palvelu.someturva.fi",
    },
  },
  se: {
    "sv-se": {
      default: siteCountry === "se",
      path: `sv-se`,
      locale: `sv-se`,
      siteLanguage: `sv-se`,
      ogLanguage: `sv-se`,
      dateFormat: `DD.MM.YYYY`,
      defaultTitle: `SomeBuddy`,
      defaultDescription: `En onlinetjänst som erbjuder hjälp för dig som upplevt problem eller blivit utsatt på sociala medier`,
      langName: "På Svenska",
      url: `https://somebuddy.se`,
      country: `Sverige`,
      social: {
        twitter: "https://twitter.com/someturva",
        instagram: "https://www.instagram.com/somebuddy_sverige/",
        facebook: "https://www.facebook.com/SomeBuddy-Sverige-101858401273518/",
        linkedin: "https://www.linkedin.com/company/someturva",
      },
      showBanner: false,
      showNewsletterSubscription: false,
      contactEmail: "info@somebuddy.se",
      katiEmail: "kati.nevatalo@somebuddy.se",
      contactPhone: "+358505053134",
      appLink: "https://app.somebuddy.com",
    },
    "en-us": {
      default: false,
      path: `en-us`,
      locale: `en-us`,
      siteLanguage: `en-us`,
      ogLanguage: `en-us`,
      dateFormat: `DD.MM.YYYY`,
      defaultTitle: `SomeBuddy`,
      defaultDescription: ``,
      langName: `English`,
      url: `https://somebuddy.se`,
      country: `Sverige`,
      social: {
        twitter: "https://twitter.com/someturva",
        instagram: "https://www.instagram.com/somebuddy_sverige/",
        facebook: "https://www.facebook.com/SomeBuddy-Sverige-101858401273518/",
        linkedin: "https://www.linkedin.com/company/someturva",
      },
      showBanner: false,
      showNewsletterSubscription: false,
      contactEmail: "info@somebuddy.se",
      katiEmail: "kati.nevatalo@somebuddy.se",
      contactPhone: "+358505053134",
      appLink: "https://app.somebuddy.com",
    },
  },
  global: {
    "en-us": {
      default: siteCountry === "global",
      path: `en-us`,
      locale: `en-us`,
      siteLanguage: `en-us`,
      ogLanguage: `en-us`,
      dateFormat: `DD.MM.YYYY`,
      defaultTitle: `SomeBuddy`,
      defaultDescription: ``,
      langName: `English`,
      url: `https://somebuddy.com`,
      country: `Global`,
      social: {
        twitter: "https://twitter.com/someturva",
        instagram: "https://www.instagram.com/someturva/",
        facebook: "https://www.facebook.com/Someturva/",
        linkedin: "https://www.linkedin.com/company/someturva",
      },
      showBanner: false,
      showNewsletterSubscription: false,
      contactEmail: "info@somebuddy.com",
      katiEmail: "kati.nevatalo@somebuddy.com",
      contactPhone: "+358505053134",
    },
  },
}
