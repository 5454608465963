import { useState, useContext, useEffect } from "react"
import cc from "classcat"
import { LocaleContext } from "contexts"
import useTranslations from "hooks/useTranslations"
import useShowLocaleSwitch from "hooks/useShowLocaleSwitch"
import Cookies from "js-cookie"

import Modal from "components/molecules/Modal"
import LanguageSelector from "components/molecules/LanguageSelector"
import { COOKIE_FUNCTIONAL_ENABLED_NAME } from "utils/constants"
import { handleOpenChat } from "utils/browser"
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "components/atoms/Accordion"
const Button = ({
  children,
  className,
  onClick,
  disabled = false,
  selected = false,
}) => {
  return (
    <button
      onClick={!disabled && onClick}
      className={cc([
        " whitespace-pre border border-teal-400 px-4 py-3 font-display text-sm leading-6 text-zinc-800 hover:bg-teal-500 focus:outline-none active:bg-teal-400 disabled:cursor-not-allowed disabled:bg-zinc-200 disabled:opacity-50 sm:py-2",
        className,
        {
          "rounded-full": !className || !className.includes("rounded"),
          "bg-teal-400": selected,
        },
      ])}
      type="button"
      disabled={disabled}
    >
      {children}
    </button>
  )
}

const P = ({ children }) => {
  return (
    <p className="leading-0 mb-0 max-w-md whitespace-pre-line text-left text-lg font-bold text-zinc-900">
      {children}
    </p>
  )
}
const SmallP = ({ children }) => {
  return <p className="mb-0 mt-2 text-sm leading-6 text-zinc-900">{children}</p>
}

const FunctionalCookieBanner = ({
  show = false,
  setShow,
  dispatchCookieUpdate,
}) => {
  const { locale } = useContext(LocaleContext)
  const { showLocalesSwitch, locales } = useShowLocaleSwitch()
  const [currentLocale, setCurrentLocale] = useState(locale)

  const {
    functionalCookieBannerText,
    functionalCookieBannerExplainer,
    cookieBannerFunctionalDescription,
    functionalCookieBannerButtonAccept,
    functionalCookieBannerButtonReject,
    cookieBannerLinkText,
    cookiePolicyLink,
  } = useTranslations(currentLocale)

  const enableFunctional = () => {
    Cookies.set(COOKIE_FUNCTIONAL_ENABLED_NAME, true, { expires: 365 })
    handleOpenChat()
    closeModal()
    setTimeout(() => {
      dispatchCookieUpdate({ functional: "enabled" })
    }, 2000)
  }

  const disableFunctional = () => {
    Cookies.set(COOKIE_FUNCTIONAL_ENABLED_NAME, false, { expires: 365 })
    dispatchCookieUpdate({ functional: "disabled" })
    closeModal()
  }

  const onRejectFunctional = () => {
    disableFunctional()
  }

  const onAcceptFunctional = () => {
    enableFunctional()
  }

  const closeModal = () => {
    setShow(false)
  }

  const handleLocaleChange = (value) => {
    setCurrentLocale(value)
  }

  return (
    <Modal
      isOpen={show}
      onRequestClose={closeModal}
      showCloseButton={false}
      closeOnClickAway={true}
    >
      <div className="w-xl -mb-px border-0 px-2 py-0">
        <div className="items-center space-y-4 text-center align-middle ">
          <div className="max-w-md">
            <P>{functionalCookieBannerText}</P>
            <Accordion type="single" collapsible>
              <AccordionItem value="item-1">
                <AccordionTrigger className="text-left font-body font-bold text-zinc-600">
                  {functionalCookieBannerExplainer}
                </AccordionTrigger>
                <AccordionContent>
                  {cookieBannerFunctionalDescription}

                  <SmallP>
                    <a
                      href={cookiePolicyLink}
                      className="underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>{cookieBannerLinkText}</span>
                    </a>
                    <span>.</span>
                  </SmallP>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
          <div className="space-y-3 sm:flex sm:space-y-0">
            <div className="flex flex-col space-y-3 xs:flex-row xs:space-x-2 xs:space-y-0 sm:flex-1">
              <Button onClick={onRejectFunctional} className="flex-1 sm:block">
                {functionalCookieBannerButtonReject}
              </Button>
              <Button
                onClick={onAcceptFunctional}
                className="flex-1 bg-teal-400 sm:block "
              >
                {functionalCookieBannerButtonAccept}
              </Button>
            </div>
            {showLocalesSwitch ? (
              <div className="mx-auto flex w-36 items-center justify-end">
                <LanguageSelector
                  locales={locales}
                  handleOnChange={handleLocaleChange}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default FunctionalCookieBanner
