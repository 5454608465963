import { Fragment } from "react"
import { Link } from "gatsby"
import cc from "classcat"
import { Popover, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/20/solid"

type MenuDropdownProps = {
  items: Array<{
    name: string
    path: string
    key: string
    isDefault: boolean
  }>
  title: string
  currentPath: string
  linkStyle: string
  white: boolean
}

export const MenuDropdown = ({
  items,
  title,
  linkStyle,
  white,
  currentPath,
}: MenuDropdownProps) => {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={cc([
              "inline-flex items-center gap-x-1 text-sm font-semibold leading-6 ",
              {
                "text-sm font-normal text-teal-100": !linkStyle && white,
              },
              {
                "text-sm font-bold text-teal-600": !linkStyle && !white,
              },
              {
                "text-base font-bold text-teal-400": linkStyle === "mobile",
              },
              { "text-gray-500": open },
            ])}
          >
            <span>{title}</span>
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
              <div className="max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                <div className="space-y-2 p-4">
                  {items.map((item) => (
                    <Link
                      to={
                        item.isDefault
                          ? currentPath || "/"
                          : `/${item.path}${currentPath}`
                      }
                      className={cc([
                        "group relative flex items-center gap-x-6 rounded-lg bg-zinc-50 p-4 leading-5 hover:bg-kkm-green/10  focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 ",
                        {
                          "text-sm font-bold text-teal-600":
                            linkStyle !== "mobile",
                        },
                        {
                          "text-base font-bold text-teal-400":
                            linkStyle === "mobile",
                        },
                      ])}
                      key={item.key}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}
