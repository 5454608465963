import { useContext } from "react"
import { navigate } from "gatsby"
import useTranslations from "hooks/useTranslations"
import IconClose from "images/icon-close.svg"
import IconSpeaker from "images/icon-speaker.svg"
import { LocaleContext } from "contexts"
import { siteCountry } from "utils/env"
import countries from "../../../config/i18n"

const locales = countries[siteCountry] || countries.fi

const DefaultIcon = () => (
  <span className="flex rounded-lg bg-teal-800 p-2">
    <IconSpeaker className="h-6 w-6 text-white" />
  </span>
)

const Banner = ({ handleClose, Icon = false }) => {
  const { bannerShort, bannerLong, bannerButton, bannerLink } =
    useTranslations()
  const { locale } = useContext(LocaleContext)

  const handleNavigation = (e) => {
    e.preventDefault()
    const path = locales[locale].default
      ? bannerLink
      : `/${locales[locale].path}${bannerLink}`

    handleClose()
    navigate(path)
  }
  const handleKeyUp = (e) => {
    if (e.key && e.key === "Enter") {
      handleNavigation(e)
    }
  }
  return (
    <div className="fixed inset-x-0 bottom-0 z-10 pb-2 sm:px-20 sm:pb-4">
      <div className="mx-auto max-w-screen-xl px-2 sm:px-3">
        <div className="mx-auto rounded-lg bg-teal-600 px-3 py-3 shadow-lg sm:py-2 xl:w-max">
          <div className="flex flex-wrap items-center justify-between sm:space-x-4">
            <div className="flex flex-1 items-center">
              {Icon ? (
                <Icon className="h-12 w-12 text-white" />
              ) : (
                <DefaultIcon />
              )}
              <p className="m-0 ml-3 truncate whitespace-pre-wrap font-medium text-white">
                <span className="lg:hidden">{bannerShort}</span>
                <span className="hidden lg:inline">{bannerLong}</span>
              </p>
            </div>
            <div className="order-3 mt-2 w-full shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
              <div className="rounded-md shadow-sm">
                <a
                  role="link"
                  tabIndex="0"
                  onKeyUp={handleKeyUp}
                  onClick={handleNavigation}
                  className="flex cursor-pointer items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium leading-5 text-teal-600 transition duration-150 ease-in-out hover:text-teal-500 focus:outline-none focus:ring"
                >
                  {bannerButton}
                </a>
              </div>
            </div>
            <div className="order-2 shrink-0 sm:order-3">
              <button
                type="button"
                className="-mr-1 flex rounded-md p-2 transition duration-150 ease-in-out hover:bg-teal-500 focus:bg-teal-500 focus:outline-none"
                onClick={handleClose}
              >
                <IconClose />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
