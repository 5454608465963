import PropTypes from "prop-types"

// https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-plugin-google-analytics/src/index.js

/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
const OutboundLink = (props) => {
  const { eventCategory, eventAction, eventLabel, eventValue, ...rest } = props
  return (
    <a
      {...rest}
      onClick={(e) => {
        if (typeof props.onClick === `function`) {
          props.onClick(e)
        }
        let redirect = true
        if (
          e.button !== 0 ||
          e.altKey ||
          e.ctrlKey ||
          e.metaKey ||
          e.shiftKey ||
          e.defaultPrevented
        ) {
          redirect = false
        }
        if (props.target && props.target.toLowerCase() !== `_self`) {
          redirect = false
        }
        if (window.ga) {
          window.ga(`send`, `event`, {
            eventCategory: eventCategory || `Outbound Link`,
            eventAction: eventAction || `click`,
            eventLabel: eventLabel || props.href,
            eventValue,
            transport: redirect ? `beacon` : ``,
            hitCallback() {
              if (redirect) {
                document.location = props.href
              }
            },
          })
        } else if (redirect) {
          document.location = props.href
        }

        return false
      }}
    />
  )
}

/* eslint-disable react/require-default-props */
OutboundLink.propTypes = {
  href: PropTypes.string,
  target: PropTypes.string,
  eventCategory: PropTypes.string,
  eventAction: PropTypes.string,
  eventLabel: PropTypes.string,
  eventValue: PropTypes.number,
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
}

export { OutboundLink }
