import PropTypes from "prop-types"
import cc from "classcat"

const CoreButton = ({ bgStyle, label, className, textStyle, size = "xl" }) => {
  return (
    <div
      tabIndex={0}
      role="button"
      className={cc([
        "pointer focus:shadow-outline-teal min-w-full max-w-md whitespace-pre-wrap rounded-full border-2 border-solid text-center font-display font-bold leading-snug transition duration-150 ease-in-out focus:outline-none ",
        {
          "border-teal-400 bg-teal-400 text-zinc-800 hover:bg-teal-500 focus:border-teal-700":
            bgStyle === "primary",
          "border-teal-700 bg-teal-700 text-zinc-200 hover:bg-teal-800 focus:border-teal-300":
            bgStyle === "dark",
          "border-teal-300 bg-zinc-100 text-zinc-800 hover:bg-zinc-300 focus:border-teal-500":
            bgStyle === "light",
          "border-kkm-blue bg-kkm-blue text-zinc-800 hover:bg-kkm-blue/50 focus:bg-kkm-blue/70":
            bgStyle === "blue",
          "border-kkm-yellow/10 bg-kkm-yellow text-zinc-700 hover:bg-kkm-yellow/80 focus:border-kkm-yellow/40":
            bgStyle === "yellow",
          "hover:bg--cadetblue-400 border-cadetblue-200 bg-cadetblue-200 text-zinc-900 focus:border-cadetblue-500":
            bgStyle === "cadetblue",
          "border-zinc-800 bg-zinc-800 text-white focus:border-zinc-400 active:border-zinc-700 active:bg-zinc-700  lg:py-4":
            bgStyle === "black",
          "border-kkm-green bg-kkm-green text-zinc-800 hover:bg-teal-500 focus:border-teal-700":
            bgStyle === "green",
          "border-kkm-darkblue bg-kkm-darkblue text-zinc-100 hover:bg-kkm-darkblue/50 focus:bg-kkm-darkblue":
            bgStyle === "darkblue",
          "border-kkm-darkgreen bg-kkm-darkgreen text-zinc-100 hover:bg-kkm-darkgreen/50 focus:bg-kkm-darkgreen":
            bgStyle === "darkgreen",
          "border-kkm-darkyellow bg-kkm-darkyellow text-zinc-100 hover:bg-kkm-darkyellow/50 focus:bg-kkm-darkyellow":
            bgStyle === "darkyellow",
          "border-kkm-darkorange bg-kkm-darkorange text-zinc-100 hover:bg-kkm-darkorange/50 focus:bg-kkm-darkorange":
            bgStyle === "darkorange",
          "border-kkm-darkred bg-kkm-darkred text-zinc-100 hover:bg-kkm-darkred/50 focus:bg-kkm-darkred":
            bgStyle === "darkred",
          "border-kkm-red bg-kkm-red text-zinc-100 hover:bg-kkm-red/50 focus:bg-kkm-red":
            bgStyle === "red",
          "px-6 py-3 text-base": size === "xl",
          "px-5 py-2 text-base": size === "lg",
          "px-4 py-2 text-sm": size === "md",
          "px-3.5 py-2 text-sm": size === "sm",
          "px-3 py-1.5 text-xs": size === "xs",
        },
        className,
      ])}
    >
      {label}
    </div>
  )
}

CoreButton.defaultProps = {
  bgStyle: "primary",
  label: "",
}

CoreButton.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.string.isRequired,
  ]),
  bgStyle: PropTypes.oneOf([
    "primary",
    "light",
    "dark",
    "blue",
    "yellow",
    "cadetblue",
    "black",
    "green",
  ]),
}

export default CoreButton
