import { useContext } from "react"
import { LocaleContext } from "contexts"
import countries from "../../config/i18n"
import { siteCountry } from "../utils/env"

const useShowLocaleSwitch = () => {
  const { locale } = useContext(LocaleContext)
  const country = countries[siteCountry]
  const locales = Object.keys(country).map((lang) => ({
    lang: country[lang].langName,
    key: country[lang].langName,
    path: country[lang].path,
    active: locale === lang,
    isDefault: country[lang].default,
    locale: country[lang].locale,
  }))

  if (locales.length < 2) return { showLocalesSwitch: false, locales }
  return { showLocalesSwitch: true, locales }
}

export default useShowLocaleSwitch
