import MenuFullWidth from "components/organisms/common/MenuFullWidth"

const Header = (props) => {
  return (
    <header className="absolute left-0 right-0 z-20">
      <MenuFullWidth {...props} />
    </header>
  )
}

export default Header
