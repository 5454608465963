import SometurvaLogo from "images/logo-someturva.svg"
import SomebuddyLogo from "images/logo-somebuddy.svg"
import SometurvaLogoBW from "images/logo-someturva-bw.svg"
import SomebuddyLogoBW from "images/logo-somebuddy-bw.svg"
import LogoSomebuddyPro from "images/logo-somebuddy-pro.svg"
import LogoSometurvaPro from "images/logo-someturva-pro.svg"
import LogoSomebuddyProBW from "images/logo-somebuddy-pro-bw.svg"
import LogoSometurvaProBW from "images/logo-someturva-pro-bw.svg"

import { siteCountry } from "utils/env"

const logoByCountry = {
  global: SomebuddyLogo,
  se: SomebuddyLogo,
  fi: SometurvaLogo,
}

export const getLogoByCountry = () => logoByCountry[siteCountry]

const footerLogoByCountry = {
  global: SomebuddyLogoBW,
  se: SomebuddyLogoBW,
  fi: SometurvaLogoBW,
}

const footerLogoByRoute = {
  global: {
    "/pro": LogoSomebuddyProBW,
    "/pro/courses": LogoSomebuddyProBW,
  },
  fi: {
    "/schools": SometurvaLogo,
    "/pro": LogoSometurvaProBW,
    "/pro/courses": LogoSometurvaProBW,
    "/pro/onboarding": LogoSometurvaProBW,
  },
  se: {
    "/schools": SomebuddyLogo,
  },
}

const headerLogoByRoute = {
  global: {
    "/pro": LogoSomebuddyPro,
    "/pro/courses": LogoSomebuddyPro,
  },
  fi: {
    "/pro": LogoSometurvaPro,
    "/pro/courses": LogoSometurvaPro,
    "/pro/onboarding": LogoSometurvaPro,
  },
}

export const getFooterLogoByCountry = () => footerLogoByCountry[siteCountry]

export const removeLocaleFromPath = (path, locale) =>
  path.replace(`/${locale}`, "")

export const getCustomLogo = (path, locale) => {
  return headerLogoByRoute?.[siteCountry]?.[removeLocaleFromPath(path, locale)]
}

export const getCustomFooterLogo = (path, locale) => {
  return footerLogoByRoute?.[siteCountry]?.[removeLocaleFromPath(path, locale)]
}

export const getLogoLink = (path, locale) => {
  return (
    removeLocaleFromPath(path, locale)
      .split("/")
      .filter((el, index, arr) => index < arr.length - 2)
      .join("/") || "/"
  )
}
