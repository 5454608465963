import { useContext } from "react"
import { Link } from "gatsby"
import { LocaleContext, LocationContext } from "../contexts"
import countries from "../../config/i18n"
import { siteCountry } from "../utils/env"

const locales = countries[siteCountry] || countries.fi

// Use the globally available context to choose the right path
const LocalizedLink = ({ to = "/", ...props }) => {
  const { locale } = useContext(LocaleContext)
  const { location } = useContext(LocationContext)

  // if we just need to go to anchor on the same page
  if (to?.startsWith("#")) {
    return <Link {...props} to={location.pathname + to} />
  }

  const isIndex = to === `/`
  // If the homepage/index page is linked don't add the "to"
  // Because otherwise this would add a trailing slash
  const path = locales[locale].default
    ? to
    : `/${locales[locale].path}${isIndex ? `` : `${to}`}`

  return <Link {...props} to={path} />
}

export default LocalizedLink
