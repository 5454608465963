import PropTypes from "prop-types"

const Content = ({ children }) => (
  <div className="flex min-h-full grow-0 flex-col">{children}</div>
)

Content.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Content
