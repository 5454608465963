import { Fragment, useContext } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import cc from "classcat"
import kebabCase from "lodash.kebabcase"
import { Popover, Transition } from "@headlessui/react"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  ChevronDownIcon,
  XMarkIcon,
  Bars3Icon,
  UserGroupIcon,
  AcademicCapIcon,
  CakeIcon,
  SignalIcon,
  BriefcaseIcon,
  NewspaperIcon,
  InformationCircleIcon,
  GlobeEuropeAfricaIcon,
  AdjustmentsVerticalIcon,
  Square3Stack3DIcon,
  BookOpenIcon,
  PhoneIcon,
  PlayCircleIcon,
} from "@heroicons/react/24/solid"
import { LocaleContext } from "contexts"
import { OutboundLink } from "components/atoms/OutboundLink"
import LocalizedLink from "components/LocalizedLink"
import LogoHeader from "components/atoms/common/LogoHeader"
import Button from "components/buttons/Button"
import { siteCountry } from "utils/env"
import useTranslations from "hooks/useTranslations"
import Cookies from "js-cookie"
import { COOKIE_NOTICE_ACCEPTED_NAME } from "utils/constants"
import countries from "../../../../config/i18n"
import { MenuDropdown } from "./MenuDropdown"

const PopoverButton = ({
  opened = false,
  title,
  className = "inline-flex",
  white,
}) => (
  <Popover.Button
    className={cc([
      "group items-center rounded-full py-1 pl-3 pr-2 text-sm font-bold focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 sm:text-base",
      {
        "text-zinc-100 hover:text-zinc-300": white,
        "text-zinc-600 hover:text-zinc-900 ": !white,
      },
      className,
    ])}
  >
    <span>{title}</span>
    <ChevronDownIcon
      className={cc([
        "ml-1 h-5 w-5 group-hover:text-zinc-500",
        {
          "rotate-180 transform": opened,
          "text-zinc-100": white,
          "text-zinc-600": !white,
        },
      ])}
      aria-hidden="true"
    />
  </Popover.Button>
)

const ProductIcon = ({ Icon }) => {
  return (
    <div className=" shrink-0">
      <span className="inline-flex items-center justify-center w-6 h-6 text-white rounded-full bg-kkm-green xl:h-12 xl:w-12">
        <Icon className="w-4 h-4 xl:h-8 xl:w-8" aria-hidden="true" />
      </span>
    </div>
  )
}

const ProdcutCardLink = ({ href, outbound, children, className }) =>
  outbound ? (
    <OutboundLink className={className} href={href}>
      {children}
    </OutboundLink>
  ) : (
    <Popover.Button as={LocalizedLink} className={className} to={href}>
      {children}
    </Popover.Button>
  )

const ProductCard = ({ name, href, description, Icon, outbound }) => {
  const { navMenuProductCardMore } = useTranslations()
  return (
    <div className="flex xl:shadow-lg">
      <ProdcutCardLink
        key={name}
        href={href}
        outbound={outbound}
        className="flex flex-col justify-between flex-1 px-2 py-2 -mx-2 rounded-full text-kkm-green hover:bg-kkm-green hover:text-white xl:m-0 xl:rounded-lg xl:bg-kkm-darkgreen xl:p-4"
      >
        <div className="flex items-center lg:h-full lg:flex-col xl:items-start">
          <ProductIcon Icon={Icon} />
          <div className="flex items-center ml-4 lg:flex-1 lg:flex-col lg:items-start lg:justify-between xl:ml-0 xl:mt-4">
            <div className="">
              <p className="mb-0 text-sm font-bold text-zinc-800 xl:text-base">
                {name}
              </p>
              <p className="hidden mt-1 mb-0 text-sm text-zinc-800 xl:block">
                {description}
              </p>
            </div>
            <p className="hidden mt-2 mb-0 text-sm font-bold text-teal-100 xl:mt-4 xl:block">
              {navMenuProductCardMore} <span aria-hidden="true">&rarr;</span>
            </p>
          </div>
        </div>
      </ProdcutCardLink>
    </div>
  )
}

const WideMenuWrapper = ({ children }) => (
  <div className="max-w-screen-xl mx-auto">{children}</div>
)

const NarrowMenuWrapper = ({ children }) => (
  <div className="max-w-md mx-auto rounded-lg">{children}</div>
)

const ProductsSection = ({ list }) => {
  return (
    <WideMenuWrapper>
      <div
        className={cc([
          " grid gap-x-8 gap-y-1 md:grid-cols-2 xl:gap-4 xl:py-0",
          { "xl:grid-cols-4": list.length === 4 },
          { "xl:grid-cols-3": list.length === 3 },
          { "xl:grid-cols-2": list.length === 2 },
        ])}
      >
        {list
          .filter((p) => !p.hide)
          .map((item) => (
            <ProductCard {...item} />
          ))}
      </div>
    </WideMenuWrapper>
  )
}

const SectionSubtitle = ({ text }) => {
  return (
    <h3 className="mb-0 text-sm font-medium tracking-wide uppercase text-zinc-200">
      {text}
    </h3>
  )
}

const BlogPostCard = ({ post }) => {
  const slug = `/blog/${post?.slug?.text || kebabCase(post.title.text)}/`
  return (
    <Popover.Button
      as={LocalizedLink}
      key={slug}
      to={slug}
      className="flex p-3 -m-3 rounded-lg hover:bg-zinc-100"
    >
      <div className="hidden shrink-0 sm:block">
        <GatsbyImage
          image={post.image_main.gatsbyImageData}
          className="object-cover w-32 h-20 rounded-md"
        />
      </div>
      <div className="flex-1 w-0 sm:ml-8">
        <h4 className="mb-0 text-base font-medium text-zinc-900">
          {post.title.text}
        </h4>
        <p className="mt-1 mb-0 text-sm text-zinc-800">{post.spoiler?.text}</p>
      </div>
    </Popover.Button>
  )
}

const RecentBlogPostsSection = ({ blogPosts }) => {
  const { navMenuBlog, navMenuBlogAll } = useTranslations()

  return (
    <div className="px-4 shadow-lg rounded-xl bg-kkm-darkgreen py-7 sm:px-6 lg:px-8 xl:pl-12">
      <div className="space-y-6">
        <SectionSubtitle text={navMenuBlog} />
        <ul className="space-y-6">
          {blogPosts.map((post) => (
            <BlogPostCard post={post.data} key={post.id} />
          ))}
        </ul>
      </div>
      <div className="mt-6 text-sm font-medium">
        <Popover.Button
          as={LocalizedLink}
          to="/blog"
          className="text-teal-800 hover:text-black"
        >
          {navMenuBlogAll} <span aria-hidden="true">&rarr;</span>
        </Popover.Button>
      </div>
    </div>
  )
}

const LinkListItemInner = ({ name, Icon, onClick }) => {
  const onKeyUp = (e) => {
    if (e.key && e.key === "Enter") {
      onClick(e)
    }
  }
  return (
    <div
      className="flex items-start px-3 py-2 -m-3 text-sm font-medium rounded-full text-zinc-100 hover:bg-zinc-50 hover:text-zinc-700"
      onClick={onClick}
      role="button"
      tabIndex="0"
      onKeyUp={onKeyUp}
    >
      <Icon className="w-6 h-6 shrink-0 text-zinc-400" aria-hidden="true" />
      <span className="ml-3">{name}</span>
    </div>
  )
}

const LinkListItem = ({ name, href, Icon, outbound, onClick, Component }) => {
  return (
    <Popover.Button as="li" className="flow-root" key={name}>
      {outbound && href && (
        <OutboundLink href={href}>
          <Component name={name} Icon={Icon} />
        </OutboundLink>
      )}
      {onClick && <Component name={name} Icon={Icon} onClick={onClick} />}
      {!outbound && href && (
        <Popover.Button as={LocalizedLink} to={href}>
          <Component name={name} Icon={Icon} />
        </Popover.Button>
      )}
    </Popover.Button>
  )
}

const LinksList = ({ title, list, className }) => {
  return (
    <div className="space-y-5">
      {title ? <SectionSubtitle text={title} /> : null}
      <ul className={className ?? "space-y-4"}>
        {list.map((item) => (
          <LinkListItem {...item} Component={LinkListItemInner} />
        ))}
      </ul>
    </div>
  )
}

const getOtherCountriesLinks = () => {
  const { [siteCountry]: remove, ...otherCountries } = countries
  return Object.values(otherCountries).map((c) => {
    const { country, url } = Object.values(c)[0]
    return {
      name: country,
      href: url,
      Icon: GlobeEuropeAfricaIcon,
      outbound: true,
    }
  })
}

const MoreLinksSection = () => {
  const {
    navMenuOtherCompany,
    navMenuOtherResources,
    menuLinkAboutUs,
    menuLinkMedia,
    menuLinkLegal,
    menuLinkBlog,
    menuLinkCourses,
    menuLinkHelpdesk,
    helpdeskLink,
    menuLinkCountry,
    menuLinkCookiePrefs,
  } = useTranslations()

  const { locale } = useContext(LocaleContext)

  const company = [
    { name: menuLinkAboutUs, href: "/us", Icon: CakeIcon },
    { name: menuLinkMedia, href: "/media", Icon: SignalIcon },
    { name: menuLinkLegal, href: "/legal", Icon: BriefcaseIcon },
  ]

  const blog = { name: menuLinkBlog, href: "/blog", Icon: NewspaperIcon }

  const helpdesk = {
    name: menuLinkHelpdesk,
    href: helpdeskLink,
    Icon: InformationCircleIcon,
    outbound: true,
  }

  const cookieSettings = {
    name: menuLinkCookiePrefs,
    Icon: AdjustmentsVerticalIcon,
    onClick: () => {
      Cookies.set(COOKIE_NOTICE_ACCEPTED_NAME, "false", {
        expires: 365,
      })
      window.location.reload()
    },
  }

  const companyLinksByCountry = {
    global: {
      "en-us": [
        { name: menuLinkMedia, href: "/media", Icon: SignalIcon },
        { name: menuLinkLegal, href: "/legal", Icon: BriefcaseIcon },
      ],
      "fi-fi": [
        { name: menuLinkMedia, href: "/media", Icon: SignalIcon },
        { name: menuLinkLegal, href: "/legal", Icon: BriefcaseIcon },
      ],
    },
    fi: {
      "fi-fi": company,
      "sv-fi": company,
      "en-fi": company,
    },
    se: {
      "sv-se": [
        { name: menuLinkAboutUs, href: "/us", Icon: CakeIcon },
        { name: menuLinkMedia, href: "/media", Icon: SignalIcon },
        { name: menuLinkLegal, href: "/legal", Icon: BriefcaseIcon },
      ],
      "en-us": [
        { name: menuLinkAboutUs, href: "/us", Icon: CakeIcon },
        { name: menuLinkMedia, href: "/media", Icon: SignalIcon },
        { name: menuLinkLegal, href: "/legal", Icon: BriefcaseIcon },
      ],
    },
  }

  const resourcesLinksByCountry = {
    global: {
      "en-us": [blog, cookieSettings],
      "fi-fi": [
        {
          name: menuLinkCourses,
          href: "/pro/courses",
          Icon: AcademicCapIcon,
        },
        blog,
        cookieSettings,
      ],
    },
    fi: {
      "fi-fi": [blog, helpdesk, cookieSettings],
      "sv-fi": [blog, helpdesk, cookieSettings],
      "en-fi": [blog, helpdesk, cookieSettings],
    },
    se: {
      "sv-se": [blog, cookieSettings],
      "en-us": [blog, cookieSettings],
    },
  }

  return (
    <nav className="flex flex-col divide-y grow divide-zinc-500">
      <div className="grid grid-cols-2 grow gap-x-4 py-7 sm:gap-x-8 lg:px-4 xl:px-8">
        <LinksList
          title={navMenuOtherCompany}
          list={companyLinksByCountry[siteCountry][locale]}
        />
        <LinksList
          title={navMenuOtherResources}
          list={resourcesLinksByCountry[siteCountry][locale]}
        />
      </div>
      <div className="pb-5 pt-7 lg:px-4 xl:px-8">
        <LinksList
          title={`${menuLinkCountry} / Switch region`}
          list={getOtherCountriesLinks()}
          className="flex space-x-8 space-y-0"
        />
      </div>
    </nav>
  )
}

const TwoSectionsPanel = ({ blogPosts }) => {
  return (
    <div className="relative">
      <div className="absolute inset-0 flex">
        <div className="w-1/2" />
        <div className="w-1/2" />
      </div>
      <WideMenuWrapper>
        <div className="relative grid grid-cols-1 gap-4 lg:grid-cols-2">
          <div className="flex flex-col shadow-lg rounded-xl bg-zinc-700">
            <MoreLinksSection />
          </div>
          <RecentBlogPostsSection blogPosts={blogPosts} />
        </div>
      </WideMenuWrapper>
    </div>
  )
}

const DropdownLink = ({ name, Icon }) => {
  return (
    <div
      key={name}
      className="flex items-center p-2 -m-2 transition duration-150 ease-in-out rounded-lg hover:bg-zinc-600 focus:outline-none focus-visible:ring focus-visible:ring-teal-500 focus-visible:ring-opacity-50"
    >
      <Icon
        aria-hidden="true"
        className="flex items-center justify-center w-6 h-6 text-white shrink-0"
      />
      <div className="ml-3">
        <p className="m-0 text-base font-bold text-white whitespace-nowrap">
          {name}
        </p>
      </div>
    </div>
  )
}

const LinkList = ({ list }) => {
  return (
    <div className="relative grid gap-4 p-4 pb-2 bg-zinc-700">
      {list.map((item) => (
        <LinkListItem {...item} Component={DropdownLink} />
      ))}
    </div>
  )
}

const PopoverMenu = ({ title, children, className, white }) => {
  return (
    <Popover>
      {({ open }) => (
        <>
          <PopoverButton
            title={title}
            className={cc(["inline-flex"], className)}
            white={white}
            opened={open}
          />
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 -translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-1"
          >
            <Popover.Panel className="absolute z-20 inset-x-3 top-full">
              {children}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

const DropdownMenu = ({ title, children, className, white }) => {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <PopoverButton
            title={title}
            opened={open}
            className={cc(["inline-flex"], className)}
            white={white}
          />
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-20 max-w-sm mt-4 transform -left-1 sm:px-0 lg:max-w-3xl">
              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <NarrowMenuWrapper>{children}</NarrowMenuWrapper>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

const MenuLink = ({
  title,
  to,
  className = "inline-flex font-bold",
  white,
  outbound = false,
}) => {
  return outbound ? (
    <OutboundLink
      href={to}
      className={cc([
        "group items-center rounded-full px-3 py-1 text-sm leading-5 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 sm:text-base",
        {
          "text-zinc-100 hover:text-zinc-300": white,
          "text-zinc-600 hover:text-zinc-900 ": !white,
        },
        className,
      ])}
    >
      {title}
    </OutboundLink>
  ) : (
    <LocalizedLink
      to={to}
      className={cc([
        "group items-center rounded-full px-3 py-1 text-sm leading-5 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 sm:text-base",
        {
          "text-zinc-100 hover:text-zinc-300": white,
          "text-zinc-600 hover:text-zinc-900 ": !white,
        },
        className,
      ])}
    >
      {title}
    </LocalizedLink>
  )
}

const MenuButtons = () => {
  const { buttonLogin, appLink } = useTranslations()

  return (
    <Button bgStyle="primary" label={buttonLogin} link={appLink} outbound />
  )
}

const callsToAction = [
  { name: "Watch demo", href: "#", icon: PlayCircleIcon },
  { name: "Contact sales", href: "#", icon: PhoneIcon },
]

const LocalesSwitch = ({ className, linkStyle, white = false }) => {
  const { locale } = useContext(LocaleContext)
  const { [locale]: currentLocale, ...otherCountryLocales } =
    countries[siteCountry]
  const locales = Object.values(otherCountryLocales).map((loc) => ({
    name: loc.langName,
    key: loc.langName,
    path: loc.path,
    isDefault: loc.default,
  }))

  const currentPath =
    typeof window !== "undefined"
      ? window.location.pathname.replace(/\/\w\w-\w\w/, "")
      : "/"

  if (locales.length > 0) {
    return (
      <MenuDropdown
        items={locales}
        footer={callsToAction}
        title={currentLocale.langName}
        currentPath={currentPath}
        linkStyle={linkStyle}
        white={white}
      />
    )
  }
}

export const MobilePopover = ({ open, list }) => {
  const {
    navMenuCompanies,
    navMenuSchools,
    menuLinkCompaniesMainFull,
    menuLinkCompaniesTrainingsFull,
    menuLinkCompaniesOnboarding,
    menuLinkSchoolsOnboarding,
  } = useTranslations()
  const { locale } = useContext(LocaleContext)

  const companyLink = {
    name: menuLinkCompaniesMainFull,
    href: "/pro",
    Icon: UserGroupIcon,
  }

  const companyTrainingLink = {
    name: menuLinkCompaniesTrainingsFull,
    href: "/pro/courses",
    Icon: AcademicCapIcon,
    hide: locale === "en-fi",
  }

  const companyOnboardingLink = {
    name: menuLinkCompaniesOnboarding,
    href: "/pro/onboarding",
    Icon: BookOpenIcon,
  }

  const schoolOnboardingLink = {
    name: menuLinkSchoolsOnboarding,
    href: "/schools/onboarding",
    Icon: BookOpenIcon,
  }

  const companiesInboundLink = {
    name: navMenuCompanies,
    href: "/pro",
    Icon: BriefcaseIcon,
    outbound: false,
  }
  const schoolsInboundLink = {
    name: navMenuSchools,
    href: "/schools",
    Icon: AcademicCapIcon,
    outbound: false,
  }

  const mobileLinks = {
    fi: [companyLink, companyTrainingLink, companyOnboardingLink, ...list],
    se: [companyLink, schoolsInboundLink],
    global: [companiesInboundLink, schoolsInboundLink],
  }

  return (
    <Transition
      show={open}
      as={Fragment}
      enter="duration-200 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <Popover.Panel
        focus
        static
        className="absolute inset-x-0 top-0 z-30 p-2 transition origin-top-right transform sm:left-1/4 lg:hidden xl:left-1/2"
      >
        <div className="bg-white divide-y-2 shadow-lg divide-zinc-50 rounded-xl ring-1 ring-black ring-opacity-5">
          <div className="p-4">
            <div className="flex items-center justify-between ">
              <div className="flex flex-row items-center space-x-4 align-start">
                <MenuButtons />
                <LocalesSwitch linkStyle="mobile" />
              </div>

              <div className="-mr-2 -mt-7">
                <Popover.Button className="inline-flex items-center justify-center p-2 bg-white rounded-md text-zinc-400 hover:bg-zinc-100 hover:text-zinc-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-teal-500">
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <nav className="mt-4 lg:hidden xl:mt-8">
              <ProductsSection list={mobileLinks[siteCountry]} />
            </nav>
          </div>

          {/* {siteCountry !== "global" && ( */}
          <div className="px-5 rounded-lg bg-zinc-700 lg:hidden">
            <MoreLinksSection />
          </div>
          {/* )} */}
        </div>
      </Popover.Panel>
    </Transition>
  )
}

export const MobileMenuButton = ({ white }) => {
  return (
    <Popover.Button
      className={cc([
        "inline-flex items-center justify-center rounded-md  border p-2 hover:bg-zinc-100 hover:text-zinc-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-teal-500",
        {
          "text-white": white,
          "text-zinc-600": !white,
        },
      ])}
    >
      <span className="sr-only">Open menu</span>
      <Bars3Icon className="w-6 h-6" aria-hidden="true" />
    </Popover.Button>
  )
}

export default function MenuFullWidth({ white, Logo, logoLink }) {
  const { locale } = useContext(LocaleContext)
  const {
    navMenuSchools,
    navMenuCompanies,
    navMenuOther,
    menuLinkSchoolMain,
    menuLinkSchoolTrainings,
    menuLinkSchoolLessons,
    menuLinkCompaniesMain,
    menuLinkCompaniesTrainings,
    menuLinkCompaniesOnboarding,
    menuLinkSchoolsOnboarding,
    menuLinkParentsLessons,
  } = useTranslations()
  const blogPosts = useStaticQuery(graphql`
    query BlogPostsQuery {
      allPrismicBlogPost(sort: { data: { publication_date: DESC } }) {
        nodes {
          id
          lang
          data {
            image_main {
              gatsbyImageData(width: 144)
            }
            publication_date
            show_in_menu
            spoiler {
              text
            }
            tags
            title {
              text
            }
            slug {
              text
            }
          }
        }
      }
    }
  `)

  const lastBlogPostForLocale = blogPosts.allPrismicBlogPost.nodes
    .filter(({ lang }) => lang === locale)
    .slice(0, 1)

  const schoolLinks = [
    {
      name: menuLinkSchoolMain,
      href: "/schools",
      Icon: UserGroupIcon,
      hide: siteCountry !== "fi",
    },
    {
      name: menuLinkSchoolTrainings,
      href: "/schools/trainings",
      Icon: AcademicCapIcon,
      hide: siteCountry !== "fi",
    },
    {
      name: menuLinkSchoolLessons,
      href: "/lessons/schools",
      Icon: Square3Stack3DIcon,
      hide: siteCountry !== "fi",
    },
    {
      name: menuLinkSchoolsOnboarding,
      href: "/schools/onboarding",
      Icon: BookOpenIcon,
    },
    // {
    //   name: menuLinkParentsLessons,
    //   href: "/lessons/parents",
    //   Icon: UsersIcon,
    //   hide: siteCountry !== "fi",
    // },
  ]

  const companyLink = {
    name: menuLinkCompaniesMain,
    href: "/pro",
    Icon: UserGroupIcon,
  }

  const companyTrainingLink = {
    name: menuLinkCompaniesTrainings,
    href: "/pro/courses",
    Icon: AcademicCapIcon,
    hide: locale === "en-fi",
  }

  const companyOnboardingLink = {
    name: menuLinkCompaniesOnboarding,
    href: "/pro/onboarding",
    Icon: BookOpenIcon,
  }

  const companiesInboundLink = {
    title: navMenuCompanies,
    href: "/pro",
    type: "link",
    outbound: false,
  }

  const menuLinks = {
    fi: [
      {
        title: navMenuCompanies,
        type: "dropdown",
        outbound: false,
        children: (
          <LinkList
            list={[
              companyLink,
              companyTrainingLink,
              companyOnboardingLink,
            ].filter((p) => !p.hide)}
          />
        ),
      },
      {
        title: navMenuSchools,
        type: "dropdown",
        outbound: false,
        children: <LinkList list={schoolLinks.filter((p) => !p.hide)} />,
      },
      {
        title: navMenuOther,
        type: "popover",
        outbound: false,
        children: <TwoSectionsPanel blogPosts={lastBlogPostForLocale} />,
      },
    ],
    se: [
      {
        title: navMenuCompanies,
        href: "/pro",
        type: "link",
        outbound: false,
      },
      {
        title: navMenuSchools,
        href: "/schools",
        type: "link",
        outbound: false,
      },
      {
        title: navMenuOther,
        type: "popover",
        outbound: false,
        children: <TwoSectionsPanel blogPosts={lastBlogPostForLocale} />,
      },
    ],
    global: [
      companiesInboundLink,
      {
        title: navMenuSchools,
        href: "/schools",
        type: "link",
        outbound: false,
      },
      {
        title: navMenuOther,
        type: "popover",
        outbound: false,
        children: <TwoSectionsPanel blogPosts={lastBlogPostForLocale} />,
      },
    ],
  }

  return (
    <Popover className="relative">
      <>
        <div className="inset-0 z-30 pointer-events-none " aria-hidden="true" />
        <div className="z-20">
          <div className="relative py-2 mx-auto md:space-x-4">
            <div className="flex items-center space-x-4">
              <div className="flex flex-1 px-4 mx-auto space-x-4 max-w-7xl sm:px-6 xl:px-8">
                <LogoHeader white={white} Logo={Logo} to={logoLink} />
                <div className="hidden pt-2 md:flex-1 md:items-center lg:flex">
                  <Popover.Group
                    as="nav"
                    className="flex space-x-3 align-center"
                  >
                    <>
                      {menuLinks[siteCountry].map((link) => {
                        // when no dropdown needed
                        if (link.type === "link") {
                          return (
                            <MenuLink
                              title={link.title}
                              to={link.href}
                              white={white}
                              outbound={link.outbound}
                              key={link.title}
                            />
                          )
                        }
                        // for centered wide menus
                        if (link.type === "popover") {
                          return (
                            <PopoverMenu
                              title={link.title}
                              white={white}
                              key={link.title}
                            >
                              {link.children}
                            </PopoverMenu>
                          )
                        }
                        // for list of links below button
                        if (link.type === "dropdown") {
                          return (
                            <DropdownMenu
                              title={link.title}
                              white={white}
                              key={link.title}
                            >
                              {link.children}
                            </DropdownMenu>
                          )
                        }
                        return null
                      })}
                    </>
                  </Popover.Group>
                </div>
              </div>
            </div>
            <div className="absolute top-0 bottom-0 right-0 flex items-center pr-4 align-center lg:hidden">
              <MobileMenuButton white={white} />
            </div>
            <div className="absolute bottom-0 right-0 flex-row items-center hidden pr-4 space-x-4 align-center top-2 md:ml-12 lg:flex">
              <LocalesSwitch white={white} />
              <div className="items-center md:ml-12">
                <MenuButtons />
              </div>
            </div>
          </div>
        </div>
      </>
      <MobilePopover list={[...schoolLinks]} />
    </Popover>
  )
}
