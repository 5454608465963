import { useEffect } from "react"
import { Transition } from "@headlessui/react"

const Modal = ({
  isOpen,
  onRequestClose,
  showCloseButton = true,
  closeOnClickAway = true,
  children,
}) => {
  useEffect(() => {
    if (isOpen === true) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "visible"
    }
  }, [isOpen])

  const handleClick = () => {
    if (closeOnClickAway) onRequestClose()
  }

  return (
    <Transition
      show={isOpen}
      enter="transition ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      aria-hidden="true"
    >
      <div className="fixed inset-0 overflow-y-auto z-over-crisp">
        <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 transition"
            aria-hidden="true"
            onClick={handleClick}
          >
            <div className="absolute inset-0 opacity-75 bg-zinc-500" />
          </div>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          />
          <div
            className="inline-block px-4 pt-4 pb-4 text-left align-bottom transform bg-white rounded-lg shadow-xl sm:my-8 sm:max-w-screen-md sm:p-6 sm:align-middle"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            {children}
            {showCloseButton ? (
              <div className="absolute top-0 right-0 block">
                <button
                  onClick={handleClick}
                  type="button"
                  className="p-3 rounded-md text-zinc-400 hover:text-zinc-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="sr-only">Close</span>
                  <svg
                    className="w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Transition>
  )
}

export default Modal
