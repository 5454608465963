export const stripeKey = process.env.GATSBY_STRIPE_KEY

export const stripeSKUBaseOneCase = process.env.GATSBY_STRIPE_BASE_1_CASE
export const stripeSKUBaseSubscription =
  process.env.GATSBY_STRIPE_BASE_SUBSCRIPTION
export const stripeSKUParentalOneCase =
  process.env.GATSBY_STRIPE_PARENTAL_1_CASE
export const stripeSKUParentalSubscription =
  process.env.GATSBY_STRIPE_PARENTAL_SUBSCRIPTION
export const stripeSKUInfluencerOneCase =
  process.env.GATSBY_STRIPE_INFLUENCER_1_CASE
export const stripeSKUInfluencerOneCasePartner =
  process.env.GATSBY_STRIPE_INFLUENCER_1_CASE_PARTNER
export const stripeSKUInfluencerSubscription =
  process.env.GATSBY_STRIPE_INFLUENCER_SUBSCRIPTION
export const stripeSKUInfluencerSubscriptionPartner =
  process.env.GATSBY_STRIPE_INFLUENCER_SUBSCRIPTION_PARTNER

export const siteUrl = process.env.GATSBY_SITE_URL
export const siteCountry = process.env.GATSBY_SITE_COUNTRY

export const stripeCheckoutEndpoint =
  process.env.GATSBY_STRIPE_CHECKOUT_ENDPOINT

export const contactFormEndpoint = process.env.GATSBY_CONTACT_FORM_ENDPOINT
export const formPdfEndpoint = process.env.GATSBY_FORM_PDF_ENDPOINT
