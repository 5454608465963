import scrollTo from "gatsby-plugin-smoothscroll"
import Cookies from "js-cookie"
import {
  COOKIE_FUNCTIONAL_ENABLED_NAME,
  COOKIE_ANALYTICS_ENABLED_NAME,
} from "utils/constants"

export const handleScrollToAnchor = (link = "") => {
  const anchor = link || window.location.hash
  if (anchor && document.getElementById(anchor.replace("#", ""))) {
    scrollTo(anchor)
  }
}

export const maybeLoadCrispChat = () => {
  const crispOpt = { controlCookieName: COOKIE_FUNCTIONAL_ENABLED_NAME }
  if (
    typeof window.loadCrisp === `function` &&
    Cookies.get(crispOpt.controlCookieName) === "true"
  ) {
    if (process.env.NODE_ENV === "development") {
      console.log(
        `onClientEntry - Cookies.get('${crispOpt.controlCookieName}') is true ==> start crisp chat`,
      )
    }
    loadCrispChat()
  }
}

export const maybeLoadPlausible = () => {
  const crispOpt = { controlCookieName: COOKIE_ANALYTICS_ENABLED_NAME }
  if (Cookies.get(crispOpt.controlCookieName) === "true") {
    if (process.env.NODE_ENV === "development") {
      console.log(
        `onClientEntry - Cookies.get('${crispOpt.controlCookieName}') is true ==> start Plausible analytics`,
      )
    }
    loadPlausible()
  }
}

export const loadCrispChat = () => {
  window.$crisp = []
  window.CRISP_WEBSITE_ID = process.env.GATSBY_CRISP_ID

  return new Promise((resolve, reject) => {
    const s = document.createElement("script")
    s.src = "https://client.crisp.chat/l.js"
    s.async = true
    s.onload = () => resolve("Crisp loaded successfully.")
    s.onerror = (error) => reject(error)
    document.head.appendChild(s)
  })
}

export const loadPlausible = () => {
  return new Promise((resolve, reject) => {
    const s = document.createElement("script")
    s.src = "/js/script.js"
    s.setAttribute("defer", true)
    s.setAttribute("data-domain", process.env.GATSBY_TARGET_BUCKET)
    s.onload = () => resolve("Plausible loaded successfully.")
    s.onerror = (error) => reject(error)
    document.head.appendChild(s)
  })
}

export const handleOpenChat = async () => {
  if (window.$crisp) {
    window.$crisp.push(["do", "chat:open"])
  } else {
    await loadCrispChat()
    window.$crisp.push(["do", "chat:open"])
  }
}
