import { useState, useMemo } from "react"
import Cookies from "js-cookie"
import { siteCountry } from "utils/env"
import { COOKIE_FUNCTIONAL_ENABLED_NAME } from "utils/constants"
import { LocaleContext, LocationContext, BannerContext } from "../contexts"
import i18nConfig from "../../config/i18n"
import CookieBanner from "components/molecules/CookieBanner"
import FunctionalCookieBanner from "components/molecules/FunctionalCookieBanner"
import SomebuddyFooter from "components/molecules/common/Footer"
import NotificationBanner from "components/molecules/Banner"
import Header from "./Header"
import Content from "./Content"

import {
  getLogoByCountry,
  removeLocaleFromPath,
  getCustomLogo,
  getLogoLink,
} from "../utils/locale"
import CrispButton from "../images/crisp-button.svg"

const routesWithWhiteNavigationByCountry = {
  fi: ["/parents/", "/kuntavaalit/", "/schools/national-training-day/"],
  se: ["/parents/", "/kuntavaalit/"],
  global: ["/"],
}

const isWhiteNavigation = (path, locale) => {
  return routesWithWhiteNavigationByCountry[siteCountry].includes(
    removeLocaleFromPath(path, locale),
  )
}

const footerCustomColor = {
  "/schools/onboarding/": "bg-zinc-700 text-zinc-100",
}

const buttonCustomColor = {
  // "/schools": "green",
}

const noCookieBanner = ["/legal/cookie-policy/"]

const shouldShowCookieBanner = (path, locale) => {
  return !noCookieBanner.includes(removeLocaleFromPath(path, locale))
}

const PageContent = ({ children }) => <div className="grow">{children}</div>
const CrispChatButton = ({ onClick }) => (
  <a
    className="fixed bottom-[14px] right-[14px] z-10 block cursor-pointer no-underline sm:bottom-[20px]  sm:right-[24px]"
    tabIndex="0"
    role="button"
    aria-label="Open chat"
    onClick={onClick}
  >
    <span className="block h-[54px] w-[54px] rounded-full bg-[#393832] pt-0.5 sm:h-[60px] sm:w-[60px]">
      <span className="flex h-full w-full items-center justify-center">
        <CrispButton />
      </span>
    </span>
  </a>
)

const Layout = ({ children, pageContext: { locale }, location }) => {
  const countryOptions = i18nConfig[siteCountry]
  const localeOptions = countryOptions[locale]
  const [cookieState, setCookieState] = useState({})
  const [showBanner, setShowBanner] = useState(localeOptions.showBanner)
  const [showFunctionalCookieBanner, setShowFunctionalCookieBanner] =
    useState(false)
  const localeContextValue = useMemo(() => ({ locale }), [locale])
  const locationContextValue = useMemo(() => ({ location }), [location])

  const bannerContextValue = useMemo(
    () => ({ showBanner, setShowBanner }),
    [showBanner],
  )
  const { pathname } = location
  const white = isWhiteNavigation(pathname, locale)
  const showCookieBanner = shouldShowCookieBanner(pathname, locale)
  const functionalEnabled = Cookies.get(COOKIE_FUNCTIONAL_ENABLED_NAME)

  const showCrispButton =
    functionalEnabled === "false" || cookieState.functional === "disabled"
  const logoLink = getLogoLink(pathname, locale)
  const LocalizedLogo = getLogoByCountry(locale)
  const LogoComponent = getCustomLogo(pathname, locale) || LocalizedLogo

  return (
    <Content>
      <LocaleContext.Provider value={localeContextValue}>
        <LocationContext.Provider value={locationContextValue}>
          <Header white={white} Logo={LogoComponent} logoLink={logoLink} />
          <PageContent>{children}</PageContent>
          <SomebuddyFooter
            className={
              footerCustomColor[removeLocaleFromPath(pathname, locale)]
            }
            buttonStyle={
              buttonCustomColor[removeLocaleFromPath(pathname, locale)]
            }
          />
          {showCookieBanner ? (
            <CookieBanner
              dispatchCookieUpdate={(v) =>
                setCookieState((prev) => ({ ...prev, ...v }))
              }
            />
          ) : null}
          {showCrispButton ? (
            <CrispChatButton
              onClick={() => setShowFunctionalCookieBanner(true)}
            />
          ) : null}
          <FunctionalCookieBanner
            show={showFunctionalCookieBanner}
            setShow={setShowFunctionalCookieBanner}
            dispatchCookieUpdate={setCookieState}
          />
          <BannerContext.Provider value={bannerContextValue}>
            {showBanner ? (
              <NotificationBanner handleClose={() => setShowBanner(false)} />
            ) : null}
          </BannerContext.Provider>
        </LocationContext.Provider>
      </LocaleContext.Provider>
    </Content>
  )
}

export default Layout
