export const fillWithUtm = (to) => {
  if (typeof window !== `undefined`) {
    const utmObject = JSON.parse(sessionStorage.getItem("utmCodes") || "{}")
    const utmVars = Object.keys(utmObject).reduce((acc, key) => {
      const prefix = acc === "" ? "" : "&"
      return `${acc}${prefix}${key}=${utmObject[key]}`
    }, "")
    return `${to}${utmVars ? `?${utmVars}` : ""}`
  }

  return to
}
